.root {
  background: var(--light-grey);
  border-radius: 16px;
  display: flex;
  padding: 16px;
  box-sizing: border-box;
  gap: 16px;
  width: 100%;
  cursor: pointer;
  height: 136px;
  outline: 2px solid transparent;
  outline-offset: -2px;
  transition: outline-color 0.3s ease-in-out;
  height: max-content;
}

.active,
.root:hover {
  outline-color: var(--orange);
}

.small {
  display: block;
}

.contentWithImage {
  display: grid;
  height: 104px;
  grid-template-columns: 96px 1fr;
  grid-template-rows: 20px 20px;
  gap: 8px 16px;
  margin-bottom: 16px;
}

.contentWithImage .time {
  margin: 0;
}
.contentWithImage .image {
  grid-row: 1/3;
}

.small .title {
  max-width: 100%;
}

.image {
  width: 96px;
  height: 104px;
  flex: none;
}

.time {
  display: block;
  margin-bottom: 12px;
  font-size: 12px;
  line-height: 20px;
  color: var(--grey);
}

.title {
  margin: 0 0 12px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: var(--dark);
  max-width: 171px;
  width: 100%;
}

.price {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: var(--orange);
}
