.ticket {
  width: 100%;
  padding: 16px 0;
  display: flex;
  align-items: start;
  border-top: 1px solid rgba(151, 157, 165, 0.2);
  border-bottom: 1px solid rgba(151, 157, 165, 0.2);
}

.ticket:last-child {
  border-top: 0px;
}

.ticket_solo:last-child {
  border-top: 1px solid rgba(151, 157, 165, 0.2);
}

.icon {
  width: 32px;
  height: 32px;
  margin-right: 18px;
}

.block {
  display: flex;
  flex-direction: column;
}

.title {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 2px;
}

.zone {
  font-weight: 800;
  font-size: 10px;
  line-height: 16px;
  margin-bottom: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: var(--grey);
}

.bottom {
  display: flex;
  gap: 12px;
  align-items: center;
}
.price {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  width: fit-content;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.dot_red {
  background-color: var(--orange);
}
.dot_green {
  background-color: var(--green);
}
.dot_purple {
  background-color: var(--purple);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 8px 16px;
  background: var(--light-grey);
  border-radius: 24px;
  margin-left: auto;
}
