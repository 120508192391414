.root {
  display: flex;
  flex-direction: column;
  width: 390px;
}

.main {
  margin: 0 16px;
}

.top {
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 16px;
}

.image {
  width: 75px;
  height: 75px;
}

.title {
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 8px;
}

.zone {
  width: fit-content;
  display: block;
  padding: 4px 16px;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 10px;
  line-height: 16px;
  color: var(--grey);
  background: var(--light-grey);

  border-radius: 24px;
  margin-bottom: 36px;
}

.scrollbar {
  margin-bottom: 36px;
  height: 150px !important;
}

.options {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.options_title {
  font-weight: 600;
}

.options_list {
  display: flex;
  flex-direction: column;
  list-style: disc;
  padding-left: 20px;
}

.price {
  display: block;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: var(--orange);
  margin-bottom: 42px;
}

.close {
  cursor: pointer;
}

.button {
  height: 50px;
}
