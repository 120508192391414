@font-face {
  font-family: "Manrope";
  font-weight: 400;
  src: url("./assets/fonts/ManropeRegular.ttf") format("truetype");
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  font-weight: 500;
  src: url("./assets/fonts/ManropeMedium.ttf") format("truetype");
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  font-weight: 700;
  src: url("./assets/fonts/ManropeBold.ttf") format("truetype");
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  font-weight: 800;
  src: url("./assets/fonts/ManropeExtraBold.ttf") format("truetype");
  font-display: swap;
  font-style: normal;
}

:root {
  --dark: #18232e;
  --secondary-dark: #000000;
  --light: #ffffff;
  --grey: #979da5;
  --light-grey: #f5f6fb;
  --orange: #ff4718;
  --purple: #ac5cde;
  --green: #02de8f;
  --yellow: #ffd600;
}

body {
  margin: 0;
  font-family: "Manrope", Helvetica;
  font-weight: 400;
  min-width: 375px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

button {
  font: inherit;
  color: inherit;
  border: none;
  background: transparent;
}

a {
  text-decoration: none;
  color: inherit;
}

a:focus {
  outline: none;
}

ul,
ol {
  list-style: none;
}

.leaflet-container .leaflet-control-attribution {
  display: none;
}

.react-datepicker {
  border: none;
  border-radius: 16px;
}

.react-datepicker-wrapper {
  display: none;
}

.react-datepicker__tab-loop {
  display: flex;
}

.react-datepicker-popper {
  transform: none !important;
  position: static !important;
  margin: 0 auto;
  padding: 0 !important;
}

.react-datepicker__header {
  background-color: var(--light);
  border-bottom: none;
}

.react-datepicker__day-names {
  margin: 0;
  display: flex;
  gap: 16px;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__day {
  color: var(--dark);
  font-size: 12px;
  line-height: 20px;
  background: var(--light-grey);
  margin: 0;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;

  transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
}

.react-datepicker__week {
  gap: 16px;
  display: flex;
}

.react-datepicker__month {
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.react-datepicker__day-name {
  margin: 0;
  color: #979da5;
  width: 36px;
  height: 36px;
  font-weight: 800;
}

.react-datepicker__day:hover {
  border-radius: 50%;
  background: var(--orange);
  color: #fff;
}

.react-datepicker__day--today {
  font-weight: inherit;
}

.react-datepicker__current-month {
  color: #18232e;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  margin-bottom: 24px;
}

.react-datepicker__day--selected {
  background: var(--orange);
  color: #fff;
}

.react-datepicker__day--outside-month {
  opacity: 0.2;
}

.react-datepicker__day--outside-month:hover {
  opacity: 1;
}

.react-datepicker__day--disabled {
  opacity: 0.2;
  cursor: default;
}

.react-datepicker__day--disabled:hover {
  background: #fff;
  opacity: 0.2;
  color: #18232e;
}

.react-datepicker__header {
  padding: 0 !important;
}

.react-datepicker__navigation--previous {
  left: 27px;
  outline: none;
  top: -10px;
}

.react-datepicker__navigation--next {
  outline: none;
  right: 27px;
  top: -10px;
}

.react-datepicker__navigation-icon--previous::before {
  background: url("./assets/images/arrow.svg") !important;
  border: none;
  transform: none;
  width: 24px;
  height: 24px;
}

.react-datepicker__navigation-icon--next::before {
  background: url("./assets/images/arrow.svg") !important;
  border: none;
  transform: none;
  width: 24px;
  height: 24px;
  transform: rotate(180deg);
}

.react-datepicker__navigation-icon {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.react-datepicker__navigation-icon:hover {
  opacity: 0.7;
}

.react-datepicker__day--selected:hover,
.react-datepicker__month-text--selected:hover {
  background: var(--orange);
  color: #fff;
}

.ScrollbarsCustom-TrackY {
  width: 4px !important;
  right: 10px !important;
}

.edit .ScrollbarsCustom-TrackY {
  right: -20px !important;
}

.ScrollbarsCustom-Wrapper {
  inset: 0 !important;
}

@media (max-width: 500px) {
  .edit .ScrollbarsCustom-TrackY {
    right: -10px !important;
  }
}
