.root {
  display: flex;
  flex-direction: column;
  background-color: var(--light);
  padding: 32px;
  border-radius: 16px;
  width: 450px;
  min-height: 468px;
}

.wrapper {
  min-height: 100%;
  padding: 20px 0;
  display: flex;
}

.content {
  min-height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
}

.top {
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 24px;
}

.title {
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
}

.options {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
}

.options_title {
  font-weight: 800;
  font-size: 10px;
  line-height: 16px;
  color: var(--grey);
  margin-bottom: 8px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.price {
  display: flex;
  justify-content: space-between;
}

.price__sum {
  font-weight: 700;
}

.close {
  cursor: pointer;
}

.button {
  height: 52px;
}

.scrollbar {
  margin-bottom: 32px;
}

@media (max-width: 500px) {
  .root {
    padding: 24px 16px;
    width: 100%;
    border-radius: 16px 16px 0 0;
  }
  .wrapper {
    padding-bottom: 0;
  }
  .content {
    margin-bottom: 0;
    width: 100%;
  }
}
