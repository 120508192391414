.root {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  z-index: 1;
  max-width: unset;
}

.tagVisible {
  bottom: 152px !important;
}

.navVisible {
  bottom: 180px !important;
}

.map {
  max-width: unset;
  width: 100%;
  height: 100%;
  user-select: none;
}

.image {
  max-width: unset;
  background: var(--dark);
  transition: filter 0.4s ease-in;
  filter: brightness(43%);
}

.image_zone {
  filter: brightness(100%);
}

.overlay {
  opacity: 1;
  transition: opacity 0.4s ease-in;
}

.overlay_zone {
  opacity: 0;
}

@media (max-width: 768px) {
  .tagVisible {
    bottom: 140px !important;
  }
}

@media (max-width: 600px) {
  .tagVisible {
    bottom: 160px !important;
  }
  .navVisible {
    bottom: 200px !important;
  }
}
