.root {
  position: fixed;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 21;
  overflow: auto;
}

.overlay {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  background: rgba(24, 35, 46, 0.8);
}

.overlay_blur {
  backdrop-filter: blur(18px);
}

.overlay_light {
  background: var(--light);
}

.overlay_grey {
  background: var(--light-grey);
}

.content {
  height: 100%;
  z-index: 3;
  position: relative;
}

@media (max-width: 500px) {
  .root {
    min-height: 100dvh;
  }
}
