.root {
  display: flex;
  overflow: hidden;
  background: #f5f6fb;
}

.modalWrapper {
  height: 100vh;
  overflow: auto;
  width: 100%;
  display: flex;
}

.map {
  position: absolute;
  top: 0;
  left: 0;
  max-height: 100vh;
  width: 100%;
  object-fit: cover;
}

.modalContent {
  margin: auto;
  padding: 20px 0;
}

@media (max-width: 1100px) {
  .modalContent {
    padding: 0;
  }
}

@media (max-width: 900px) {
  .modalBlock {
    border-radius: 16px;
  }
  .modalContent {
    padding: 90px 20px 20px;
  }
  .modalHeader {
    background: var(--light);
    color: var(--dark);

    border-radius: 0px 0px 16px 16px;
    padding: 20px 16px;
  }

  .modalHeaderLogo {
    width: 38px;
    height: 32px;
  }
}

@media (max-width: 768px) {
  .root {
    height: 100dvh;
  }
  .modalWrapper {
    height: 100%;
  }
}

@media (max-width: 500px) {
  .root {
    height: auto;
  }
  .modalBlock {
    border-radius: 0;
    height: calc(100dvh - 42px);
  }
  .modalWrapper {
    height: 100dvh;
  }

  .modalContent {
    padding: 42px 0 0;
    margin: 0;
  }
}
