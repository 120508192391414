.footer {
  padding: 36px;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: var(--light-grey);
  width: calc(100% - 420px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
}

.footer_opened {
  animation: FadeIn 0.3s ease-in-out forwards;
}

.footer_closed {
  animation: FadeOut 0.3s ease-in-out forwards;
}

@keyframes FadeIn {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes FadeOut {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(100%);
  }
}

.text {
  color: var(--grey);
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 800;
  display: block;
  margin-bottom: 6px;
}

.result {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--secondary-dark);
}

.price {
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: var(--dark);
  margin-right: 17px;
}

.icon {
  cursor: pointer;
  padding: 9px;
  display: flex;
}

.right {
  display: flex;
  align-items: center;
}

.left {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1000px) {
  .result {
    font-size: 14px;
    line-height: 20px;
  }
  .price {
    font-size: 18px;
    line-height: 26px;
    margin-right: 10px;
  }
  .button {
    font-size: 14px;
    line-height: 20px;
  }
}

@media (max-width: 900px) {
  .footer {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .footer {
    padding: 20px 16px;
    border-radius: 16px 16px 0 0;
    display: grid;
    grid-template-columns: 1fr 130px;
    gap: 26px;
  }
  .text {
    font-size: 10px;
    line-height: 16px;
  }
  .right {
    justify-self: end;
    align-self: end;
  }
  .result,
  .price {
    font-size: 14px;
    line-height: 20px;
    margin-right: 12px;
  }
  .icon {
    width: 24px;
    height: 24px;
    padding: 0;
  }

  .buttonBig {
    grid-column: 1/3;
  }
}
