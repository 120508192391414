.ticket {
  width: 100%;
  padding: 16px 0;
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(151, 157, 165, 0.2);
  border-bottom: 1px solid rgba(151, 157, 165, 0.2);
}

.ticket:last-child {
  border-top: 0px;
}

.icon {
  min-width: 32px;
}

.block {
  display: flex;
  flex-direction: column;
  margin: 0 auto 0 16px;
}

.title {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

.price {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.count {
  display: flex;
  align-items: center;
  padding: 9px 11px;
  background: var(--light-grey);
  border-radius: 24px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.button_disabled {
  pointer-events: none;
  cursor: default;
}

.button svg path {
  transition: all 0.3s ease-in;
  fill: var(--orange);
}

.button_disabled svg path {
  fill: var(--grey);
}

.display {
  font-size: 14px;
  line-height: 20px;
  margin: 0 16px;
  min-width: 14px;
  text-align: center;
}
