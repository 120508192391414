.root {
  display: flex;
  gap: 8px;
  align-items: center;
  width: max-content;
  user-select: none;
  pointer-events: none;
}

.tag {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;
  background: var(--light-grey);
  border-radius: 24px;
  width: fit-content;
  height: fit-content;
}

.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.anim {
  position: absolute;
  z-index: 501;
  bottom: 36px;
  left: 36px;
}

.anim_opened {
  animation: FadeIn 0.3s ease-in-out forwards;
}

.anim_closed {
  animation: FadeOut 0.3s ease-in-out forwards;
}

@keyframes FadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes FadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@media (max-width: 1100px) {
  .popupOpen {
    opacity: 0;
    visibility: hidden;
  }
}

@media (max-width: 768px) {
  .anim {
    left: 16px;
  }
}
