.aside {
  position: sticky;
  top: 0;
  z-index: 10;
  max-width: 420px;
  height: 100vh;
  overflow: hidden;
  width: 100%;
  background: var(--light);
}

.wrapper {
  padding: 0 36px 0;
}

.head {
  color: var(--dark) !important;
  position: relative !important;
  padding: 0 !important;
  position: static;
}

.head > svg {
  width: 41px;
  height: 35px;
}

.header {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  align-items: center;
  padding: 22px 0 27px;
  border-bottom: 1px solid rgba(151, 157, 165, 0.2);
  z-index: 2;
  background: var(--light);
}

.datepicker {
  z-index: 1;
  padding: 26px 0 32px;
  border-bottom: 1px solid rgba(151, 157, 165, 0.2);
}

.items {
  padding-top: 24px;
  padding-bottom: 22px;
  gap: 12px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 900px) {
  .aside {
    max-width: 310px;
  }
}

@media (max-width: 500px) {
  .aside {
    height: 100dvh;
  }
}
