.root {
  background: var(--light);
  border-radius: 16px;
  max-width: 388px;
  margin: auto;
}

.wrapper {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.title {
  padding: 25px 20px 0;
  margin-bottom: 24px;
  font-size: 36px;
  line-height: 38px;
  font-weight: 800;
  color: var(--dark);
  margin-bottom: 24px;
  display: block;
}

.datepicker {
  padding: 14px 20px 22px;
}

@media (max-width: 900px) {
  .headerText {
    display: none;
  }
}

@media (max-width: 768px) {
  .root {
    max-width: 400px;
    width: 100%;
  }
  .wrapper {
    padding: 92px 0 20px;
  }
  .title {
    padding: 15px 20px 0;
  }
  .headerText {
    display: block;
  }
}

@media (max-width: 500px) {
  .root {
    max-width: 100%;
    margin-top: 32px;
    border-radius: 0 0 16px 16px;
  }
  .wrapper {
    padding: 20px 0;
  }
}
