.root {
  color: var(--light);
  position: fixed;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 20px;
  width: 100%;
}

.route {
  display: flex;
  align-items: center;
  gap: 14px;
  cursor: pointer;
}

.text {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

@media (max-width: 768px) {
  .root {
    background: var(--light);
    color: var(--dark);

    border-radius: 0px 0px 16px 16px;
    padding: 20px 16px;
  }

  .logo {
    width: 38px;
    height: 32px;
  }
}

@media (max-width: 500px) {
  .root {
    padding: 16px;
  }
  .logo {
    width: 40px;
    height: 40px;
  }
}
