.root {
  background: #ffffff;
  border-radius: 16px;
  position: absolute;
  z-index: 500;
  top: 36px;
  left: 36px;
  padding: 24px 22px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  user-select: none;
}

.icons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 8px;
}

.date {
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #ff4718;
}

.time {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #979da5;
}

@media (max-width: 1100px) {
  .popupOpen {
    opacity: 0;
    visibility: hidden;
  }
}

@media (max-width: 768px) {
  .root {
    padding: 20px 14px 15px;
    top: 80px;
    left: 16px;
    cursor: pointer;
  }
  .date {
    font-size: 14px;
    line-height: 20px;
  }
  .time {
    font-size: 10px;
    line-height: 20px;
  }
}
