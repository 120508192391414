.root {
  background: var(--light);
  border-radius: 16px;
  padding: 32px 16px 19px;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  max-height: 553px;
  overflow: hidden;
  height: 553px;
  width: 390px;
  margin-left: 52px;
}

.wrapper {
  min-height: 100%;
  padding: 20px 0;
  display: flex;
}

.content {
  min-height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
}

.items {
  max-width: 390px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.title {
  font-weight: 800;
  font-size: 36px;
  line-height: 38px;
  color: var(--dark);
  margin: 0 0 24px;
  padding: 0 4px;
}

.additional {
  display: flex;
  flex-direction: column;
  position: relative;
  left: -52px;
  width: 0;
  height: 553px;
  overflow: hidden;
  transition: opacity 0.3 ease-in-out;
  background: var(--light-grey);
  border-radius: 16px;
  max-width: 675px;
  opacity: 0;
  padding-left: 52px;
}

.additional.active {
  width: 100%;
  opacity: 1;
}

.container {
  display: flex;
  overflow: hidden;
}

.additionalImage {
  object-fit: cover;
  max-width: 100%;
  width: 100%;
}

.additionalTextWrapper {
  padding: 36px 44px 36px 36px;
  box-sizing: border-box;
  flex: 1;
  font-size: 12px;
  line-height: 20px;
  color: var(--dark);
  margin: 0 0 20px;
}

.button {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-left: 36px;
  margin-top: auto;
  margin-bottom: 36px;
  width: max-content;
}

.button:hover {
  background: transparent;
}

@media (max-width: 1520px) {
  .headerTextActive {
    display: none;
  }
}

@media (max-width: 1100px) {
  .root {
    min-width: 270px;
    padding: 32px 0px 19px;
  }
  .title {
    margin: 0 0 14px;
    padding: 0 16px;
  }
  .items {
    padding: 10px 16px 50px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
}

@media (max-width: 905px) {
  .headerText {
    display: none;
  }
}

@media (max-width: 768px) {
  .root {
    align-self: center;
    padding: 20px 16px;
    width: 100%;
    margin: 0;
    height: fit-content;
    max-height: none;
  }
  .content {
    margin: 0 auto;
  }
  .wrapper {
    padding: 92px 0 20px;
  }
  .items {
    padding: 0;
    overflow: unset;
    width: 100%;
    max-width: 100%;
  }
  .title {
    margin: 0 auto;
    margin: 0 0 24px;
    padding: 0;
    font-size: 5vw;
  }
  .headerText {
    display: block;
  }

  .item {
    max-width: 100%;
  }
  .item h2 {
    max-width: 100%;
  }
}

@media (max-width: 500px) {
  .root {
    align-self: end;
    border-radius: 16px 16px 0 0;
  }
  .wrapper {
    padding: 48px 0 0;
  }
  .title {
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    color: var(--grey);
    text-transform: uppercase;
  }
}

@media (max-width: 350px) {
  .root {
    padding: 20px 16px;
  }
}
