.root {
  display: flex;
  flex-direction: column;
  width: 390px;
  background: var(--light);
  border-radius: 16px 16px 0 0;
  padding: 32px 16px 19px;
  box-sizing: border-box;
}

.wrapper {
  min-height: 100%;
  padding: 20px 0 0;
  display: flex;
}

.content {
  min-height: 100%;
  margin: auto auto 0;
  display: flex;
  justify-content: center;
}

.main {
  margin: 0 16px;
}

.top {
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 16px;
}

.image {
  width: 75px;
  height: 75px;
}

.title {
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 8px;
}

.zone {
  width: fit-content;
  display: block;
  padding: 4px 16px;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 10px;
  line-height: 16px;
  color: var(--grey);
  letter-spacing: 2px;
  background: var(--light-grey);

  border-radius: 24px;
  margin-bottom: 36px;
}

.options {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 36px;
}

.options_title {
  font-weight: 600;
}

.options_list {
  display: flex;
  flex-direction: column;
  list-style: disc;
  padding-left: 20px;
}

.price {
  display: block;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: var(--orange);
  margin-bottom: 42px;
}

.close {
  cursor: pointer;
}

.button {
  height: 50px;
}

@media (max-width: 500px) {
  .root {
    width: 100%;
  }
  .wrapper {
    width: 100%;
  }
  .content {
    width: 100%;
  }
}
