.root {
  background: var(--light);
  border-radius: 16px;
  padding: 24px;
  width: 640px;
  display: flex;
  flex-direction: column;
}

.title {
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  margin: 0 0 24px;
  width: 70%;
}

.loader {
  height: 25px;
  width: 25px;
}

.text {
  display: block;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 24px;
}

.tickets {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.prices {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
}

.price {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.price:last-child {
  margin-bottom: 0px;
}

.price__sum {
  font-weight: 700;
}

.button {
  width: 100%;
  padding: 15px 0;
  height: 50px;
}

.button:not(:last-of-type) {
  margin-bottom: 20px;
}

@media (max-width: 1100px) {
  .root {
    width: 100%;
    border-radius: 0;
  }
}

@media (max-width: 500px) {
  .prices {
    margin-top: auto;
  }
}
