.rect {
  border: 2px solid;
  width: 60px;
  height: 50px;
  border-radius: 8px;
  stroke: #ffffff;
  stroke-width: 2px;
  fill: var(--orange);
  opacity: 1;
}

.control {
  position: absolute;
  right: 36px;
  top: 36px;
  margin: 0 !important;
  border: 1px solid #ffffff66 !important;
  border-radius: 8px;
  outline: none;
  overflow: hidden;
}

.image {
  max-width: unset;
  background: var(--dark);
  transition: filter 0.4s ease-in;
  filter: brightness(120%);
}

.minimap {
  width: 177px;
  height: 124px;
}

@media (max-width: 768px) {
  .minimap {
    width: 134px;
    height: 102px;
  }
  .control {
    top: 80px;
    right: 16px;
  }
}
