.group {
  display: flex;
  flex-direction: column;
  min-height: 90px;
  width: 100%;
}

.group_small {
  min-height: 70px;
}

.label {
  margin: 0 0 4px;
  font-size: 12px;
  line-height: 20px;
  color: var(--grey);
}

.input {
  height: 44px;
  color: var(--dark);
  padding: 11px 12px;
  width: 100%;
  background: var(--light-grey);
  outline: none;
  border: none;
  border-radius: 8px;
  transition: all 0.3s ease-out;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  caret-color: var(--orange);
}

.container {
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  background: var(--light-grey);
  transition: all 0.3s ease-out;
  border: 1px solid var(--light-grey);
}

.action {
  margin: 0 4px 0;
  background: var(--orange);
  display: flex;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.input::placeholder {
  color: var(--grey);
  font-weight: 500;

  font-size: 17px;
  line-height: 22px;
}

.container:focus-within {
  border: 1px solid var(--orange);
}

.container.error {
  border: 1px solid var(--orange);
}

.label_error {
  text-align: start;
  margin: 5px 0 0;
  font-size: 12px;
  line-height: 12px;
  color: var(--orange);
}
