.overlay {
  outline: none !important;
  cursor: grab !important;
}

.visible {
  animation: FadeIn 1s ease-in-out forwards;
}

.text {
  cursor: pointer !important;
}

@keyframes FadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.zone {
  fill: none;
  stroke: #ee8fbc;
  stroke-width: 5.5;
  stroke-miterlimit: 10;
}

.zone1 {
  fill: none;
  stroke: #9000e7;
  stroke-width: 5.5;
  stroke-miterlimit: 10;
}

.zone2 {
  fill: none;
  stroke: #ffa600;
  stroke-width: 5.5;
  stroke-miterlimit: 10;
}

.zone3 {
  fill: none;
  stroke: #c62329;
  stroke-width: 5.5;
  stroke-miterlimit: 10;
}

.closed {
  animation: FadeIn 1s ease-in-out forwards;
}

@keyframes FadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
