.root {
  background: var(--light);
  border-radius: 16px;
  padding: 16px;
  width: 390px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.loader {
  height: 100%;
}

.wrapper {
  padding: 20px 0;
  min-height: 100%;
  display: flex;
}

.logo {
  margin-bottom: 50px;
}

.image {
  width: 186px;
  height: 186px;
  margin-bottom: 48px;
}

.title {
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  margin: 0 0 24px;
}

.text {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 70px;
}

.text_with_error {
  text-align: center;
  margin-bottom: 32px;
}

.button {
  width: 100%;
  padding: 15px 0;
  margin-bottom: 16px;
}

.button_transparent {
  border: 0px;
  background: transparent;
  color: var(--orange);
}

.button_transparent:hover {
  border: none !important;
}

.button:last-of-type {
  margin-bottom: 0;
}
