.root {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--orange);
  border-radius: 24px;
  border: none;
  border: 1px solid transparent;
  cursor: pointer;
  padding: 8px 16px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--light);
  transition: color 0.3s ease-in-out, background 0.3s ease-in-out,
    border 0.3s ease-in-out;
}

.root:not(.root_disabled):hover {
  border: 1px solid var(--orange);
  color: var(--orange);
  background: var(--light);
}

.root_outlined {
  border: 1px solid var(--orange);
  color: var(--orange);
  background: var(--light);
}

.root_disabled {
  border: 1px solid var(--grey);
  color: var(--grey);
  background: var(--light-grey);
}

.root_outlined:not(.root_disabled):hover {
  color: var(--light);
  background: var(--orange);
}
