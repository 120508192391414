.marker_icon {
  height: 20px;
  width: 20px;
  transition: opacity 1s ease-in-out;
}

.marker {
  height: 24px;
  width: 24px;
  border: 2px solid var(--light);
  background-color: var(--orange);
  border-radius: 50%;
  cursor: pointer;
  z-index: 100;
  transition: all 0.3s ease-in-out;
}
