.root {
  background: var(--light);
  border-radius: 16px;
  padding: 24px;
  width: 640px;
  margin: auto;
}

.wrapper {
  padding: 120px 0 20px;
  min-height: 100%;
  display: flex;
}

.header {
  color: #18232e;
}

.title {
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  margin: 0 0 28px;
}

.button {
  width: 100%;
  padding: 15px 0;
  height: 50px;
}

.button:first-of-type {
  margin-bottom: 20px;
}

.text {
  font-size: 14px;
  line-height: 20px;
  color: var(--grey);
  margin-top: 5px;
  margin-bottom: 32px;
}

.bonuses {
  display: flex;
  flex-direction: column;
}

.bonuses__title {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 16px;
}

.prices {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
}

.price {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.price:last-child {
  margin-bottom: 0px;
}

.price__sum {
  font-weight: 700;
}

@media (max-width: 1175px) {
  .headerText {
    display: none;
  }
}

@media (max-width: 845px) {
  .logo {
    display: none;
  }
}

@media (max-width: 768px) {
  .headerText,
  .logo {
    display: block;
  }
  .wrapper {
    padding: 92px 20px 20px;
  }
}

@media (max-width: 500px) {
  .wrapper {
    padding: 52px 0 0;
  }
}
