.root {
  display: flex;
  width: max-content;
  cursor: pointer;
}

.button {
  display: flex;
  border-radius: 24px;
  background: var(--light-grey);
  gap: 8px;
  align-items: center;
  padding: 6px 12px;
  cursor: pointer;
}

.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.anim {
  position: absolute;
  z-index: 501;
  left: 36px;
  top: 184px;
}

.anim_opened {
  animation: FadeIn 0.3s ease-in-out forwards;
}

.anim_closed {
  animation: FadeOut 0s ease-in-out forwards;
}

@keyframes FadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes FadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@media (max-width: 1100px) {
  .popupOpen {
    opacity: 0;
    visibility: hidden;
  }
}

@media (max-width: 768px) {
  .anim {
    top: auto;
    bottom: 85px;
    left: 16px;
  }

  .navClassName {
    bottom: 170px;
  }
}
