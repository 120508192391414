.react-datepicker-popper {
  margin: 0 !important;
  width: 100% !important;
}

.react-datepicker {
  width: 100% !important;
}

.react-datepicker__month-container {
  width: 100% !important;
}

.react-datepicker__day-names {
  justify-content: space-between;
}

.react-datepicker__week {
  justify-content: space-between;
}

@media (max-width: 768px) {
  .react-datepicker__navigation--previous {
    left: 0 !important;
  }
  .react-datepicker__navigation--next {
    right: 0 !important;
  }

  .react-datepicker__day-names {
    gap: 5px !important;
  }

  .react-datepicker__week {
    gap: 5px !important;
  }
}

@media (max-width: 500px) {
  .react-datepicker__day {
    width: 36px !important;
    height: 36px !important;
  }

  .react-datepicker__month {
    gap: 4vw !important;
  }

  .react-datepicker__day {
    width: 7.5vw !important;
    height: 7.5vw !important;
  }
}
