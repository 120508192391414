.leaflet-popup-content-wrapper {
  background: var(--light);
  box-shadow: none;
  border-radius: 16px;
  padding: 32px 16px 46px;
}

.leaflet-popup-content {
  margin: 0;
  display: flex;
}

.leaflet-popup-close-button {
  display: none;
}

.leaflet-popup {
  bottom: -390px !important;
  left: -375px !important;
}
.leaflet-popup-tip-container {
  top: 33% !important;
  left: 102.7% !important;
  transform: rotate(270deg);
}
.leaflet-popup-tip {
  box-shadow: none !important;
}
.leaflet-popup:before {
  content: '';
  position: absolute;
  border: 13px solid transparent;
  border-bottom-color: white;
  bottom: 0px;
  margin-left: -13px;
}
